<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" class="demo-form-inline" size="medium">
        <!--        <el-form-item prop="userCode">-->
        <!--          <el-input-->
        <!--              v-model="query.userCode"-->
        <!--              clearable-->
        <!--              placeholder="请输入用户编码"-->
        <!--              prefix-icon=""-->
        <!--              style="width: 260px"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item prop="trueName">
          <el-input v-model="query.trueName" clearable placeholder="请输入名称" prefix-icon="" class="width260"></el-input>
        </el-form-item>
        <el-form-item prop="mobileNum">
          <el-input v-model="query.mobileNum" clearable placeholder="请输入手机号码" prefix-icon=""
            class="width260"></el-input>
        </el-form-item>
        <el-form-item prop="searchDate">
          <el-date-picker v-model="query.searchDate" end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期"
            type="daterange" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="doAdd">新增</el-button>
      </div>
      <el-table v-loading="isLoading" :data="dataList" border class="common-table" size="medium" style="width: 100%">
        <el-table-column align="center" label="用户编码" prop="userCode" width="300">
        </el-table-column>
        <el-table-column align="center" label="姓名" prop="trueName" min-width="300">
        </el-table-column>
        <el-table-column align="center" label="手机号码" prop="mobileNum" width="300">
        </el-table-column>
        <el-table-column align="center" label="性别" prop="sex" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.sex === 1 ? "男" : "女" }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="地区" prop="region">
        </el-table-column>
        <!--          <el-table-column align="center" label="区划" prop="mergerName">-->
        <!--          </el-table-column>-->
        <el-table-column align="center" fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-button class="common-text" size="small" type="text" @click="doEdit(scope.row)">编辑
            </el-button>
            <el-button class="common-text" size="small" type="text" @click="goChangePassword(scope.row.id)">修改密码
            </el-button>
            <el-button class="common-text" size="small" type="text" @click="setRole(scope.row)">设置角色
            </el-button>
            <el-button class="common-text" size="small" type="text" @click="goDelete(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize" :total="pagination.totalSize"
          background layout="sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog :append-to-body="true" :visible.sync="exportDialogFormVisible" class="common-dialog" title="导出选项"
      width="600px">
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input v-model="form.exportSize" placeholder="请填写导出条数"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
    <!-- 添加或编辑运营管理人员 -->
    <el-dialog :before-close="addOrEditDialogBeforeClosed" :title="title" :visible.sync="addOrEditDialogVisible"
      append-to-body class="common-dialog" width="400px">
      <div v-loading="isAddLoading">
        <el-form ref="model" :inline="true" :model="model" :rules="rules" size="medium">
          <el-row>
            <el-col span="24">
              <el-form-item label="真实姓名" prop="trueName" required>
                <el-input v-model="model.trueName" clearable placeholder="请输入姓名" prefix-icon=""
                  class="width400"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="24">
              <el-form-item label="手机号码" prop="mobileNum" required>
                <el-input v-model="model.mobileNum" clearable placeholder="请输入手机号码" prefix-icon=""
                  class="width400"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="24">
              <el-form-item label="性别" prop="sex" required>
                <el-radio-group v-model="model.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="24">
              <el-form-item label="地区" prop="regionId">
                <el-cascader ref="regionId" v-model="model.regionId" :clearable="true" :filterable="true" :props="props"
                  :show-all-levels="false" placeholder="默认全国" />
              </el-form-item>
            </el-col>
          </el-row>



          <!--          <el-form-item label="邮箱" label-width="100px" prop="email">-->
          <!--            <el-input-->
          <!--                v-model="model.email"-->
          <!--                clearable-->
          <!--                placeholder="请输入邮箱地址"-->
          <!--                prefix-icon=""-->
          <!--                style="width: 400px"-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="选择头像" label-width="100px" prop="image">-->
          <!--            <el-upload-->
          <!--                ref="headImageUpload"-->
          <!--                :http-request="doHeadImageUpload"-->
          <!--                :show-file-list="false"-->
          <!--                action=""-->
          <!--                class="avatar-uploader"-->
          <!--                list-type="picture"-->
          <!--            >-->
          <!--              <Picture-->
          <!--                  v-if="model.image"-->
          <!--                  :attachId="model.image"-->
          <!--                  class="avatar"-->
          <!--              />-->
          <!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          <!--            </el-upload>-->
          <!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click="cancelAddOrEdit">取 消</el-button>
          <el-button type="primary" @click="saveAddOrEdit">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改密码弹窗 -->
    <el-dialog :before-close="changePasswordDialogBeforeClosed" :visible.sync="changePasswordDialogVisible"
      append-to-body class="common-dialog" title="修改密码" width="400px">
      <el-form ref="changeData" v-loading="isChangeLoading" :inline="true" :model="changeData" :rules="rulesChangeData"
        size="medium">
        <el-form-item label="修改密码" label-width="100px" prop="password" required>
          <el-input v-model="changeData.password" placeholder="请输入新密码" prefix-icon="" show-password style="width: 260px"
            type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelChangePassword">取 消</el-button>
        <el-button type="primary" @click="saveNewPassword">确定</el-button>
      </div>
    </el-dialog>
    <!-- 设置角色弹窗 -->
    <el-dialog :before-close="setRoleDialogBeforeClosed" :visible.sync="setRoleDialogVisible" append-to-body
      class="common-dialog" title="设置角色" width="400px">
      <el-form ref="setRoleDate" :inline="true" :model="setRoleData" :rules="roleData" size="medium">
        <el-form-item label="用户名" label-width="100px" prop="userName">
          <el-input v-model="setRoleData.userName" disabled placeholder="用户名" size="small"></el-input>
        </el-form-item>
        <el-form-item label="角色" label-width="100px" prop="roleName" required>
          <el-select v-model="setRoleData.roleName" clearable placeholder="请选择-角色" size="small">
            <el-option v-for="item in options" :key="item.id" :label="item.roleName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!--      <el-table-->
      <!--          v-loading="isSetLoading"-->
      <!--          :data="roleList"-->
      <!--          border-->
      <!--          class="common-table"-->
      <!--          size="medium"-->
      <!--          style="width: 100%"-->
      <!--      >-->
      <!--        <el-table-column align="center" label="角色编号" prop="roleCode">-->
      <!--        </el-table-column>-->
      <!--        <el-table-column align="center" label="角色名称" prop="roleName">-->
      <!--        </el-table-column>-->
      <!--        <el-table-column align="center" label="操作">-->
      <!--          <template slot-scope="scope">-->
      <!--            <el-button-->
      <!--                class="common-text"-->
      <!--                size="small"-->
      <!--                type="text"-->
      <!--                @click="deleteRole(scope.row.relationId)"-->
      <!--            >删除-->
      <!--            </el-button-->
      <!--            >-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogBeforeClosed(() => { })">取 消</el-button>
        <el-button type="primary" @click="addRoleList">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import { checkToken, doExport } from "@/api/common";
import { doUploadImage } from "@/api/parking";
import {
  addOperators,
  adduserrole,
  getOperators,
  getRoleList,
  getuserrole,
  removeOperators,
  removeuserrole,
  updateOperators,
  updatepassword
} from "@/api/system";
import { getRegionList } from "@/api/user";
import { MessageBox } from "element-ui";
// import Picture from "@/components/Picture";

export default {
  name: "operators",
  mixins: [BaseMixin],
  components: {
    // Picture,
  },
  data() {
    return {
      isLoading: false,
      props: {
        checkStrictly: true,
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, value } = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({ regionId: regionId })
            .then(({ returnObject }) => {
              console.log('getRegionList:', returnObject)
              const nodes = returnObject.regionResultVoList.map(item => ({
                value: item.id,
                label: item.name,
                leaf: item.level == 3,
              })
              )
              resolve(nodes)
            })
        }
      },
      query: {
        userCode: "",
        userName: "",
        mobileNum: "",
        searchDate: [],
        startDate: "",
        endDate: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [],
      addOrEditDialogVisible: false, //添加运营管理人员弹窗
      title: "",
      isAddLoading: false,
      model: {
        id: "",
        image: "", //头像
        trueName: "",
        mobileNum: "",
        sex: "1",
        email: "",
      },
      rules: {
        trueName: [{ required: true, message: "姓名不能为空" }],
        mobileNum: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("手机号不能为空"));
              } else if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "请选择性别" }],
      },
      changePasswordDialogVisible: false, //修改密码弹窗
      isChangeLoading: false, //提交修改密码loading
      changePasswordId: "",
      changeData: {
        id: "",
        password: "",
      },
      rulesChangeData: {
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("新密码不能为空"));
              }
              // else if (
              //   !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(value)
              // ) {
              //   callback(
              //     new Error(
              //       "必须包含大小写字母和数字的组合,可以使用特殊字符,长度在8-16之间"
              //     )
              //   );
              // } 
              else if (value.length < 6 || value.length > 16) {
                callback(
                  new Error(
                    "密码必须长度在6-16之间"
                  )
                );
              }
              else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      setRoleDialogVisible: false, //设置角色弹窗
      isSetLoading: false,
      options: [], //角色列表
      setRoleData: {
        userName: "",
        roleName: "",
        roleId: "",
        userId: "",
      },
      roleData: {
        roleName: [{ required: true, message: "请选择角色" }]
      },
      roleList: [],
      curRole: {},
    };
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.searchDate = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (this.query.searchDate.length > 0) {
        params["beginTime"] = this.query.searchDate[0] + " 00:00:00";
        params["endTime"] = this.query.searchDate[1] + " 23:59:59";
      }
      const res = await getOperators(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.records;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.totalPage;
        this.form.exportSize = returnObject.total;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.searchDate.length > 0) {
        params["startDate"] = this.query.searchDate[0] + " 00:00:00";
        params["endDate"] = this.query.searchDate[1] + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport("/platform/role/get", params, "运营人员.xlsx");
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //添加
    doAdd() {
      this.title = "添加运营人员";
      this.model = {
        id: "",
        image: "", //头像
        trueName: "",
        mobileNum: "",
        sex: "1",
        email: "",
      };
      this.addOrEditDialogVisible = true;
    },
    //取消添加或编辑运营人员
    cancelAddOrEdit() {
      this.addOrEditDialogVisible = false;
      this.$refs.model.resetFields();
    },
    // 添加/维护运营人员信息弹窗关闭时清理表单
    addOrEditDialogBeforeClosed(done) {
      this.$refs.model.resetFields();
      done();
    },
    //编辑运营人员
    doEdit(e) {
      this.title = "编辑运营人员";
      this.model.id = e.id;
      this.model.mobileNum = e.mobileNum;
      this.model.sex = e.sex.toString();
      this.model.trueName = e.trueName;
      this.model.image = e.avatar;
      // this.model.email = e.userProperties.email;
      this.addOrEditDialogVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传头像图片
    async doHeadImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.image = res.returnObject.id;
      }
    },
    //确定添加或编辑运营人员
    saveAddOrEdit() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          this.isAddLoading = true;
          if (this.model.id === "") {
            addOperators(this.model).then((res) => {
              setTimeout(() => {
                this.isAddLoading = false;
                if (res && res.code === 30 && res.result) {
                  this.$message.success("保存成功！");
                  this.addOrEditDialogVisible = false;
                  this.$refs.model.resetFields();
                  this.doSearch();
                }
              }, 1000);
            });
          } else {
            this.model.userId = this.model.id
            updateOperators(this.model).then((res) => {
              setTimeout(() => {
                this.isAddLoading = false;
                if (res && res.code === 30 && res.result) {
                  this.$message.success("编辑成功！");
                  this.addOrEditDialogVisible = false;
                  this.$refs.model.resetFields();
                  this.doSearch();
                }
              }, 1000);
            });
          }
        } else {
          return false;
        }
      });
    },
    //设置角色
    setRole(e) {
      console.log('角色:', e)
      this.setRoleDialogVisible = true;
      this.setRoleData.userName = e.trueName;
      this.setRoleData.userId = e.id;
      this.getUserRole(e.id);
    },
    //关闭设置角色弹窗
    setRoleDialogBeforeClosed(done) {
      this.$refs.setRoleDate.resetFields();
      this.roleList = [];
      this.setRoleDialogVisible = false;
      done();
    },
    //查询设置角色列表
    getUserRole(userId) {
      this.isSetLoading = true;
      getuserrole(userId).then(res => {
        this.isSetLoading = false;
        if (res.code == 30 && res.result) {
          this.curRole = res.returnObject;
          if (res.returnObject != null) {
            this.setRoleData.roleId = res.returnObject.id
            this.setRoleData.roleName = res.returnObject.id
          }
        }
      })
    },
    //添加设置角色
    addRoleList() {
      this.$refs.setRoleDate.validate((valid) => {
        if (valid) {
          console.log(this.setRoleData)
          const body = {
            id: this.setRoleData.userId,
            roleId: this.setRoleData.roleName
          }
          adduserrole(body).then(res => {
            if (res.code === 30 && res.result) {
              // this.getUserRole(body.userId);
              this.setRoleDialogVisible = false;
              this.$message.success("设置成功！");
            }
          })
        }
      })
    },
    //删除设置角色
    deleteRole(e) {
      this.$confirm("确定要删除该角色吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const body = {
          id: e
        }
        this.isSetLoading = true;
        removeuserrole(body).then((res) => {
          setTimeout(() => {
            this.isSetLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("删除成功！");
              this.getUserRole(this.setRoleData.userId);
            }
          }, 1000);
        });
      });
    },
    //修改密码
    goChangePassword(e) {
      this.changePasswordDialogVisible = true;
      this.changeData.id = e;
    },
    //取消修改密码
    cancelChangePassword() {
      this.changePasswordDialogVisible = false;
      this.$refs.changeData.resetFields();
    },
    //删除弹窗关闭清除表单
    changePasswordDialogBeforeClosed(done) {
      this.$refs.changeData.resetFields();
      done();
    },
    //保存修改新密码
    saveNewPassword() {
      this.$refs.changeData.validate((valid) => {
        if (valid) {
          this.isChangeLoading = true;
          updatepassword(this.changeData).then((res) => {
            this.isChangeLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("修改密码成功！");
              this.changePasswordDialogVisible = false;
              this.$refs.changeData.resetFields();
              this.doSearch();
            }
          });
        }
      });
    },
    //删除
    goDelete(e) {
      this.$confirm("确定要删除该运营人员吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const body = {
          id: e,
        };
        this.isLoading = true;
        removeOperators(body).then((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("删除成功！");
              this.doSearch();
            }
          }, 1000);
        });
      });
    },
    //查找角色列表
    getRole() {
      getRoleList({ pageNo: 1, pageSize: 999 }).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.options = res.returnObject.records;
        }
      });
    },
  },
  mounted() {
    checkToken()
      .then(res => {
        console.log('checkToken', res)
        if (!res || res.code !== 30) {
          MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
            confirmButtonText: '重新登录',
            callback: () => {
              window.localStorage.clear()
              window.$vue.$router.push('/login')
            }
          });
        } else {
          this.getRole();
        }
      })
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #0768FD;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-form-item--medium .el-form-item__label {
  width: 100px;
}
</style>
